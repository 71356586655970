.fr-view,
component-article {
    p:last-child {
        margin-bottom: 0;
    }

    div:empty,
    p:empty {
        display: none;
    }

    img {
        position: relative;
        max-width: 100%;

        &.fr-dib {
            display: block;
            float: none;

            &.fr-fil {
                margin-left: 0;
                text-align: left;
            }

            &.fr-fir {
                margin-right: 0;
                text-align: right;
            }
        }

        &.fr-dii {
            display: inline-block;
            float: none;
            margin-right: 5px;
            margin-left: 5px;
            max-width: calc(100% - (2 * 5px));
            vertical-align: bottom;

            &.fr-fil {
                float: left;
                margin: 5px 20px 5px 0;
                max-width: calc(100% - 5px);
            }

            &.fr-fir {
                float: right;
                margin: 5px 0 5px 20px;
                max-width: calc(100% - 5px);
            }
        }
    }

    video {
        margin: 5px auto;
        max-width: 100%;
    }

    // a {
    //     &.article-button {
    //         padding: 8px 14px;
    //         box-shadow: 0 3px 1px -2px color('black', 0.2), 0 2px 2px 0 color('black', 0.14), 0 1px 5px 0 color('black', 0.12);
    //         background-color: color('diy-blue');
    //         color: color('white', 0.8);
    //         font-size: 14px;
    //         font-weight: 600;
    //         cursor: pointer;
    //     }
    // }
}
