.grid {
    display: grid;
}

.grid-gap-1 {
    gap: 1rem;
}

.grid-gap-3 {
    gap: 3rem;
}

.grid-template-2 {
    @extend .grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-template-3 {
    @extend .grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-template-4 {
    @extend .grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-template-5 {
    @extend .grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-template-40-60 {
    @extend .grid;
    grid-template-columns: 40% 60%;
}
