// Image CDN
$cdn-url: 'https://static.diy-kitchens.com';

// Colors
// Names from: https://chir.ag/projects/name-that-color
$colors: (
    _black:             #001224,
    _opaque-black:      rgba(0, 18, 36, 0.9),
    _bright-red:        #B30000,
    _brilliant-orange:  #FBB72A,
    _cadet-blue:        #B0B7C1,
    _carnation:         #FC575E,
    _cream-can:         #F3DA64,
    _dark-pink:         #FC4472,
    _feature-banner-blue:#34414E,
    _diy-options-blue:  #005CB8,
    _home-page-banner:  #009CDE,
    _diy-selected:      #F36,
    _diy-unselected:    #A1A8B4,
    _diy-warn:          #FC575E,
    _gray-chateau:      #A1A8B4,
    _havelock-blue:     #419BD8,
    _houzz-green:       #4DBD14,
    _light-grey:        #C9CED7,
    _mako:              #404651,
    _mercury:           #E6E6E6,
    _moonstone-blue:    #5BAFB9,
    _munsell:           #EEF1F5,
    _outrageous-orange: #FF6738,
    _pale-sky:          #687685,
    _pompatus-pink:     #E6A9B8,
    _pot-pourri:        #EFDFD3,
    _shark:             #1B1E26,
    _spring-green:      #00E676,
    _sunglow:           #FFBF2F,
    _white:             #FFFFFF,
    _wild-sand:         #F5F5F5,
    _wisteria:          #9368B7,
    _diy-blue:          #003DA5,
    _diy-light-blue:    #03A9F4,
    _selection:         #FF3366,
    _error:             #F91645,
    _success:           #009C50,
    _warning:           #EF5350,
    _advice:            #673AB7,
    _review:            #FFBF2F,
    _copy-border:       #EEF1F5,
    _yellow:            #FFDC55,
    _link:              #007BFF,
    _link-hover:        #0056B3,
    _green:             #009900,
    _menu-dark:         rgba(25, 41, 57, 0.9),

    _brand-primary: #009CDE,
    _brand-secondary: #D0EAFB,
    _brand-lightgrey: #F2F2F2,
    _brand-dark: #1A2D3D,
    _brand-dark-contrast: #FFFFFF,
    _brand-warn: #FF6738,

    _secondary-button-hover: #34414E,
);

// Screen widths
$mobile-width: 479px;

// Fonts
$fonts: (
    opensans: '\'Open Sans\', sans-serif',
    playfair: '\'Playfair Display\', cursive',
    serif: '\'Playfair Display\', cursive',
    bitter: '\'Bitter\', sans-serif'
);

// Functions
@function image($type, $name) {
    @return url($cdn-url + '/' + $type + '/' + $name);
}

@function color($color-name, $opacity: false) {
    @if $opacity {
        @return rgba(map-get($colors, '_'+ $color-name), $opacity);
    }

    @else {
        @return map-get($colors, '_'+ $color-name);
    }
}

@function font($font-name) {
    @return unquote(map-get($fonts, $font-name));
}

// Responsive media mixin and breakpoint
$breakpoints: (
    sm: (
        min: 576px,
        max: 575.98px,
    ),
    md: (
        min: 768px,
        max: 767.98px
    ),
    lg: (
        min: 992px,
        max: 991.98px
    ),
    xl: (
        min: 1200px,
        max: 1199.98px
    ),
    xxl: (
      min: 1400px,
      max: 1399.98px,
    )
);

@mixin responsive-breakpoint-up($breakpoint) {
    $breakpoint-values: map-get($breakpoints, $breakpoint);
    $breakpoint-min: map-get($breakpoint-values, min);
    @media screen and (min-width: $breakpoint-min){
        @content;
    }
}

@mixin responsive-breakpoint-down($breakpoint) {
    $breakpoint-values: map-get($breakpoints, $breakpoint);
    $breakpoint-max: map-get($breakpoint-values, max);
    @media screen and (max-width: $breakpoint-max){
        @content;
    }
}

@mixin responsive-breakpoint-between($breakpoint-min, $breakpoint-max) {
    $breakpoint-min-values: map-get($breakpoints, $breakpoint-min);
    $breakpoint-max-values: map-get($breakpoints, $breakpoint-max);
    $breakpoint-min: map-get($breakpoint-min-values, min);
    $breakpoint-max: map-get($breakpoint-max-values, max);
    @media screen and (min-width: $breakpoint-min) and (max-width: $breakpoint-max){
        @content;
    }
}

@mixin page-padding($page-name, $padding) {
    app-root {
        .page-content {
            #{$page-name} {
                padding: $padding;
            }
        }
    }    
} 
