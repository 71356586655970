[media-thumb] {
    cursor: pointer;
}

.sticky {
    position: fixed;
    top: 0;
    margin: 0;
    padding: 0 20px;
    background-color: color('white');
    width: calc(100% - 100px);
    z-index: 100;
}