.mat-mdc-dialog-component-host {
	display: unset;
}

.mdc-button {
    &.mat-mdc-button-base {
        &.mat-mdc-outlined-button,
        &.mat-mdc-unelevated-button {
            padding: 15px 15px;
            height: 52px;
            font-size: 0.75rem;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
        }

        &.mat-primary {
            color: color('white');
        }

        &.mat-mdc-outlined-button {
            &.mat-primary {
                color: color('brand-primary');
            }
        }
    }

    &.skinny {
        &.mat-mdc-outlined-button,
        &.mat-mdc-unelevated-button {
            padding: 0 15px;
            height: 36px;
        }
    }

    &.chunky,
    &.chunky-dark {
        width: 100%;
    }

    &.chunky-dark {
        &.selected {
            &.mat-mdc-unelevated-button,
            &.mat-mdc-raised-button,
            &.mat-mdc-outlined-button {
                color: color('white');

                .mat-mdc-button-persistent-ripple {
                    &::before {
                        background: color('secondary-button-hover');
                        border-color: color('secondary-button-hover');
                        opacity: 1;
                    }
                }
            }
        }

        &:hover {
            &.mat-mdc-unelevated-button,
            &.mat-mdc-raised-button,
            &.mat-mdc-outlined-button {
                color: color('white');

                .mat-mdc-button-persistent-ripple {
                    &::before {
                        background: color('secondary-button-hover');
                        border-color: color('secondary-button-hover');
                        opacity: 1;
                    }
                }
            }
        }
    }
}

a {
    &.chunky,
    &.chunky-dark {
        width: 100%;

        .mdc-button {
            width: 100%;
        }
    }

    &.chunky-dark {
        &.selected {
            .mdc-button {
                &.mat-mdc-unelevated-button,
                &.mat-mdc-raised-button,
                &.mat-mdc-outlined-button {
                    color: color('white');

                    .mat-mdc-button-persistent-ripple {
                        &::before {
                            background: color('secondary-button-hover');
                            border-color: color('secondary-button-hover');
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .mdc-button {
            &:hover {
                &.mat-mdc-unelevated-button,
                &.mat-mdc-raised-button,
                &.mat-mdc-outlined-button {
                    color: color('white');

                    .mat-mdc-button-persistent-ripple {
                        &::before {
                            background: color('secondary-button-hover');
                            border-color: color('secondary-button-hover');
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.button-spinner {
    &.mat-mdc-unelevated-button {
        .mdc-button__label {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &.mat-primary {
            .mat-mdc-progress-spinner {
                circle {
                    stroke: color('white');
                }
            }
        }
    }

    .mat-mdc-progress-spinner {
        margin-left: 10px;
    }
}

.mat-form-field-type-mat-input {
    &.chunky {
        width: 100%;

        .mat-form-field-wrapper {
            padding: 0;
        }

        .mat-form-field-infix {
            border: 1px solid #999;
            width: 100%;
        }

        .mat-input-element,
        .mat-form-field-label {
            padding: 8px 15px;
        }

        .mat-form-field-underline {
            display: none;
        }
    }
}

.mat-mdc-form-field {
    &.simple-select {
        &:hover {
            .mat-mdc-form-field-focus-overlay {
                opacity: 0;
            }
        }

        &.mat-focused {
            .mat-mdc-form-field-focus-overlay {
                opacity: 0;
            }
        }

        .mat-mdc-text-field-wrapper {
            background: transparent;

            &.mat-focused {
                .mat-mdc-form-field-focus-overlay {
                    background: transparent;
                }
            }

            .mdc-line-ripple {
                display: none;
            }

            .mat-mdc-select {
                .mat-mdc-select-min-line {
                    font-family: font('opensans');
                    font-size: 12px;
                    line-height: 12px;
                    letter-spacing: 1.5px;
                    color: #212529;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }

    .mat-mdc-floating-label {
        mat-label {
            font-size: 0.85rem;
        }
    }
}
