@import '/src/theme/variables';

.success {
    color: color('spring-green');
}

.danger {
    color: color('diy-selected');
}

.danger-bg {
    background-color: color('diy-selected');
}

.warning {
    color: color('bright-red');
}

.no-mb {
    margin-bottom: 0 !important;
}

.no-gutter {
    padding: 0;
}

.no-left-gutter {
    padding-left: 0 !important;
}

.no-right-gutter {
    padding-right: 0 !important;
}

.text-center {
    text-align: center;
}

.align-self-center {
    align-self: center;
}

hr {
    &.narrow {
        margin: 0;
        border: 0;
        border-bottom: 1px solid color('copy-border');
    }
}

.lock-body {
    overflow: hidden;
}

.button-full {
    & + .button-full {
        margin-top: 15px;
    }

    button {
        width: 100%;
    }
}

button {
    &.button-link {
        padding: 0;
        border: 0;
        background: transparent;
        cursor: pointer;
    }
}

a {
    cursor: pointer;

    &.light {
        color: color('light');

        .mdc-button {
            &.mat-mdc-button-base {
                color: color('white');
            }
        }
    }

    &.button-link {
        font-size: 12px;
        min-height: 50px;
        font-weight: 600;
        letter-spacing: 0.1429em;
        color: color('brand-primary');
        min-height: 20px;

        &:hover {
            color: color('link-hover');
            color: rgba(25, 41, 57, 1);
        }
    }

    &.link-style {
        color: color('link');
        font-weight: 400;

        &:hover {
            color: color('link-hover');
            text-decoration: underline;
        }
    }
}

.section-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .mdc-card {
        margin: 15px;
        width: 100%;
        max-width: 250px;
        height: 200px;
        cursor: pointer;
    }
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color('black', 0.1);
    z-index: 1000;

    .loader-message {
        margin-top: 50px;
    }
}

.notice-message {
    color: color('brand-dark');
    font-size: 0.875rem;
    font-weight: 400;
}

.content-contained {
    margin: 0 auto;
    padding: 0 60px;
    max-width: 1300px;

    @media (max-width: 992px) {
        padding: 0 40px;
    }

    @media (max-width: 480px) {
        padding: 0 25px;
    }
}

header.content-contained {
    margin-top: 10px;
    min-height: 160px;

    .panel {
        display: flex;
        gap: 30px;

        .panel-left {
            flex: 50%;
        }

        .panel-right {
            flex: 50%;
        }
    }


    h1 {
        margin: 0;
        margin-bottom: 14px;
        font-size: 1.875rem;
        font-weight: 700;
        line-height: 1.875rem;
        letter-spacing: normal;
        text-transform: uppercase;

        @include responsive-breakpoint-between(md, xl) {
            font-size: 1.675rem;
        }

        @include responsive-breakpoint-down(md) {
            font-size: 1.25rem;
        }
    }

    p {
        margin-left: 0;
        border: 0;
        color: color('opaque-black');

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 30px;
        }

        a {
            color: color('link');
            font-weight: 400;

            &:hover {
                color: color('link-hover');
                text-decoration: underline;
            }
        }
    }

    em {
        strong {
            font-weight: 400;
        }
    }

    .button-container {
        margin-bottom: 35px;

        .mdc-button {
            text-transform: uppercase;
        }
    }
}

section.content-contained {
    padding-top: 56px;
    padding-bottom: 56px;

    h2 {
        margin-bottom: 1.333rem;
        color: color('opaque-black');
        font-family: font('playfair');
        font-size: clamp(1.777rem, 1vw + 1.777rem, 3.157rem);
        font-weight: 700;
        line-height: clamp(1.777rem, 1vw + 2.369rem, 4.209rem);
    }

    h3 {
        margin-top: 0;
        margin-bottom: 1.333rem;
        font-size: clamp(1.333rem, 1vw + 1.333rem, 1.777rem);
        font-weight: 600;
        letter-spacing: -0.05rem;
        line-height: clamp(1.333rem, 1vw + 1.333rem, 1.777rem);
    }

    h4 {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: clamp(1rem, 1vw + 1rem, 1rem);
        font-weight: 700;
        letter-spacing: 0.05rem;
        line-height: clamp(1rem, 1vw + 1rem, 1.333rem);
        text-transform: uppercase;
    }

    ul li {
        font-size: clamp(1rem, 1vw + 1rem, 1rem);
        font-weight: 300;
        line-height: clamp(1rem, 1vw + 1rem, 1.333rem);
        margin-bottom: 1.333rem;
    }

    p {
        font-size: clamp(1rem, 1vw + 1rem, 1rem);
        line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);
        letter-spacing: normal;

        &+h3,
        &+h4 {
            margin-top: 48px;
        }

        &.small {
            font-size: 0.75rem;
            font-weight: 300;
        }

        &.subtext {
            font-style: italic;
            font-size: 0.8rem;
            line-height: 2rem;
        }
    }

    a {
        background-color: transparent;
        color: color('link');
        text-decoration: none;

        &:hover {
            color: color('link-hover');

            &:not(.mdc-button) {
                text-decoration: underline;
            }
        }

        &.black {
            color: color('opaque-black');

            &:hover {
                color: color('opaque-black');
            }
        }
    }

    strong {
        font-weight: 400;
    }

    .mdc-button {
        padding: 26px;
        font-weight: 600;
    }

    .centered-block {
        margin: 0 auto;
        margin-bottom: 56px;
        max-width: 66%;
        text-align: center;
    }

    .panel {
        display: flex;
        gap: 30px;

        .panel-left {
            flex: 50%;
        }

        .panel-right {
            flex: 50%;
        }
    }

    @media (max-width: 992px) {
        .panel {
            flex-direction: column;

            .panel-left {
                padding-right: 0;
            }

            .panel-right {
                padding-left: 0;
            }
        }
    }
}

section.content-contained p.error-message,
.error-message {
    color: color('bright-red');
    font-size: 0.75rem;
    line-height: 1rem;
}

.no-results {

    .common-em {
        display: block;
        margin-bottom: 30px;
        font-size: 2em;
        line-height: 1em;
        font-style: normal;
        font-weight: bold;
    }
}

.price {
    .common-price-span {
        font-family: 'Open Sans';
        font-size: clamp(1rem, 1vw + 1rem, 1rem);
        font-weight: 700;
        color: rgba(0, 18, 36, 0.9);
        letter-spacing: 0.05rem;
        line-height: clamp(1rem, 1vw + 1rem, 1.333rem);
        text-transform: uppercase;

        &.currency-vat {
            font-size: 0.75rem;
            font-weight: 400;
        }
    }
}

.common-swatches {
    text-align: center;

    .active {
        .swatch {
            .selected-colour {
                display: block;
            }
        }
    }

    .swatch {
        position: relative;
        height: 42px;
        margin: 20px 6px;
        border: 0.5px solid rgba(0, 0, 0, 0.05);
        border-radius: 30px;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
        width: 42px;
        cursor: pointer;

        .swatch-innova-alabaster,
        .swatch-innova-vanilla,
        .swatch-innova-white {
            .selected-colour {
                color: color('black');
            }
        }

        .selected-colour {
            display: none;
        }

        .mat-icon {
            position: absolute;
            left: calc(50% - 12px);
            top: calc(50% - 12px);
        }
    }
}

.common-quantity {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-left: auto;
    width: auto;
    gap: 30px;

    @include responsive-breakpoint-down(md) {
        margin: 0;
        gap: 5px;
    }

    label {
        font-size: 16px;
        line-height: 1.618rem;
        color: rgba(0, 18, 36, 0.9);
        font-weight: bold;
    }

    span {
        display: flex;
        align-items: center;
    }

    .mat-icon {
        color: color('brand-primary');
        cursor: pointer
    }

    input {
        margin: 0 10px;
        padding: 5px;
        width: 64px;
        height: 26px;
        font-size: 16px;
        text-align: center;
        border: 1px solid #ced4da;
        color: #495057;
        border-radius: 0.25rem;

        &::focus {
            outline: none;
            box-shadow: none;
            border-color: #ced4da;
        }
    }
}

.item-container {
    .item {
        .common-item-title {
            font-size: clamp(1rem, 1vw + 1rem, 1rem);
            font-weight: 700;
            letter-spacing: 0;
            line-height: clamp(1.333rem, 1vw + 1.333rem, 1.333rem);
            color: rgba(0, 18, 36, 0.9);
            text-transform: none;
            display: inline-flex;
            margin-bottom: 1rem;
        }
    }
}

.common-small {
    margin: 15px 0;
    color: color('cadet-blue');
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 5.28px;
    text-align: left;
    text-transform: uppercase;
}

.desc-grid {
    .common-btn-hollow {
        font-size: 12px;
        min-height: 50px;
        font-weight: 600;
        letter-spacing: 0.1429em;
        background: transparent;
        padding: 1rem 1.5rem;
        color: #343a40;
        border: 1px solid #343a40;
        text-align: center;
        border-radius: .25rem;
        text-transform: uppercase;
        display: block;
        margin-bottom: 15px;
        width: 100%;

        &:not(:disabled) {
            border-color: #343a40;
            color: #343a40;
        }

        &:hover {
            background: #343a40;
            color: color('white');
        }
    }
}

.no-scroll {
    overflow-y: hidden;
}

.mat-dialog-content {
    display: block;
    overflow: auto;
    margin: 0 -24px;
    padding: 0 24px;

    -webkit-overflow-scrolling: touch;
}

.mat-flat-button {
    letter-spacing: 0.1rem;
}

.ultima-popup-menu {
    button {
        height: 2rem !important;
        line-height: 1rem;
    }

    .mat-menu-panel {
        margin-left: 1rem;
    }
}

.sub-head {
    padding-bottom: 20px;
    max-width: 60%;
    line-height: 2rem;
}

.text-center {
    .sub-head {
        margin: 20px auto;
    }
}

.text-right {
    text-align: right;
}

@media only screen and (max-width: 800px) {
    .sub-head {
        max-width: 100%;
    }
}

.lightgrey {
    background-color: color('brand-lightgrey');
}

.darkgrey {
    background: #A0A0A0;
}

.current {
    &.mat-menu-item {
        border-right: 4px solid color('brand-primary');
    }
}

.video-thumbnail {
    display: inline-block;
    position: relative;
    cursor: pointer;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        padding-left: 5px;
        box-shadow: 5px 5px 30px #000;
        border-radius: 60px;
        background: #FFF;
        width: 55px;
        height: 60px;
        transform: translate(-50%, -50%);
        color: #000;
        font-size: 20px;
        line-height: 60px;
        content: '▶';
    }

    &:hover::before {
        background-color: color('brand-primary');
        color: #FFF;
    }

    img {
        border: 1px solid #AAA;
    }
}

.product-details {
    display: flex;
    justify-content: center;
    padding: 30px 0;

    .left {
        flex: 0 0 auto;
        max-width: 500px;

        img {
            margin-bottom: 30px;
        }
    }

    .details {
        flex: 1 1 auto;
        margin: 0 0 0 30px;

        @include responsive-breakpoint-down(md) {
            margin: 0;
        }

        .brand-logo {
            margin: 0 20px 20px 0;
            max-width: 120px;
        }

        .price {
            margin: 25px 0;
            text-align: right;

            h5 {
                font-size: 32px;
            }

            p {
                font-size: 12px;
                line-height: 19px;
                text-transform: uppercase;
            }
        }

        .title {
            margin-bottom: 5px;
            font-size: 21px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0.1em;
            text-align: left;

            @include responsive-breakpoint-down(md) {
                font-size: 19px;
                line-height: normal;
                letter-spacing: 0.05em;
            }
        }

        p {
            color: color('black');
            font-family: 'Open Sans';
            font-size: 16px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 0.48px;
            text-align: left;
        }

        .small {
            font-size: 12px;
            line-height: 19px;
            letter-spacing: 3px;
            text-align: left;
            text-transform: uppercase;
        }

        .desc {
            line-height: 26px;
        }

        .code {
            color: #999;
            font-size: 12px;
            font-weight: 600;
        }

        th {
            padding: 10px;
            text-align: left;
        }
    }

    .extra-info {
        padding: 30px 0;

        h4 {
            margin-top: 30px;
            font-size: 1.1rem;
        }

        p {
            line-height: 1.3rem;
        }

        button {
            padding: 7px;
            border: 1px solid #999;
            width: 100%;
        }
    }

    .buy {
        align-items: center;
        justify-content: flex-end;

        .quantity {
            width: 50px;

            label {
                text-align: center;
            }

            input {
                padding: 14px;
                width: 40px;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
            }
        }

        .flex-item:last-child {
            margin-right: 0;
        }
    }

    p {
        white-space: normal;
        word-break: break-all;
    }
}

@media only screen and (max-width: 800px) {
    .product-details {
        flex-wrap: wrap;
    }
}

.tooltip {
    background-color: color('brand-primary') !important;
    color: #FFF !important;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.3rem;
    letter-spacing: 2px;
    text-align: center;
}

.link-button {
    cursor: pointer;
    color: color('diy-light-blue');
    text-decoration: underline;
}

.pill-notice {
    padding: 5px 10px;
    border-radius: 15px;
    background-color: color('ultima');
    width: fit-content;
    color: color('white');
    font-size: 0.75rem;
    font-weight: 400;
    white-space: nowrap;

    &.pill-success {
        background-color: color('success');
    }
    
    &.pill-error {
        background-color: color('error');
    }
}

@media print {
    #section-to-hide-print,
    #section-to-hide-print1,
    #hide-printing,
    #header,
    #banners,
    #footer {
        display: none;
    }

    .hide-printing {
        display: none;
    }

    .basket-drawer {
        display: none;
    }

    .alerts {
        display: none;
    }
}
