.swatch- {
    &all {
        background-image: url('https://static.diy-kitchens.com/material_swatches/all-colours.jpg');
        background-size: contain;
    }

    &innova-alabaster {
        background-color: #F0E5D2;
    }

    &innova-bespoke {
        background-color: #FFF;
        background-image: url('https://static.diy-kitchens.com/material_swatches/bespoke_270px.jpg');
        background-repeat: no-repeat;
        background-size: contain;
    }

    &innova-black {
        background-color: #121519;
        color: #FFF;
    }

    &innova-carbon {
        background-color: #4C4D4C;
        color: #FFF;
    }

    &innova-cornflower-blue {
        background-color: #b6c0bd;
    }

    &innova-cento-th-cashmere,
    &innova-cashmere {
        background-color: #BFB5AD;
        color: #FFF;
    }

    &innova-champagne {
        background-color: #837A71;
        color: #FFF;
    }

    &innova-cornflower-blue {
        background-color: #B2BBB8;
    }

    &innova-cream {
        background-color: #F0E5D2;
    }

    &innova-dakar {
        background-color: #AAA292;
        color: #FFF;
    }

    &innova-dove-grey {
        background-color: #C3C1BC;
    }

    &innova-sky-blue {
        background-color: #BED2E1;
    }

    &innova-stone-grey {
        background-color: #978F83;
    }

    &innova-almond {
        background-color: #E1DCC8;
    }

    &innova-charcoal {
        background-color: #2B2934;
        color: #FFF;
    }

    &innova-porcelain {
        background-color: #F8F7EB;
    }

    &innova-light-oak {
        background: url('https://static.diy-kitchens.com/assets/images/kitchens/swatches/rye.jpg');
    }

    &innova-silver-grey {
        background-color: #989593;
    }

    &innova-stone {
        background-color: #BBAE9E;
    }

    &innova-dust-grey {
        background-color: rgb(135, 140, 137);
    }

    &innova-peregrine-grey {
        background-color: #CCCBC0;
    }

    &innova-haworth-blue {
        background-color: #213343;
        color: #FFF;
    }

    &innova-graphite {
        background-color: #4C4D4C;
        color: #FFF;
    }

    &innova-lamp-room-grey {
        background-color: #AFAEA6;
    }

    &innova-cento-th-light-grey,
    &innova-light-grey {
        background-color: #C3C1BC;
    }

    &innova-matt-white {
        background-color: #FFF;
    }

    &innova-white {
        background-color: #FFF;
        border: 1px solid #dee2e6 !important;
    }

    &innova-mussel {
        background-color: #D1CABC;
    } 

    &innova-dark-oak {
        background: url(https://static.diy-kitchens.com/assets/images/kitchenswatches/dark-oak.jpg);
        color: #FFF;
    }

    &innova-cento-th-concrete,
    &innova-cento-concrete,
    &innova_cento-concrete {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/concrete.jpg)
    }

    &innova-cento-slate {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/slate.jpg);
        color: #FFF;
    }

    &innova-heritage-green {
        background-color: #263028;
        color: #FFF;
    }
    
    &innova-knightsbridge-blue {
        background-color: #213343;
        color: #FFF;
    }

    &innova-fir-green {
        background-image: url('https://static.diy-kitchens.com/assets/images/kitchenswatches/fir-green.jpg');
        color: #FFF;
    }

    &innova-natural-oak {
        background-image: url('https://static.diy-kitchens.com/material_swatches/oak-270px.jpg');
    }

    &innova-northern-tide {
        background-color: #749398;
    }

    &innova-oak {
        background-image: url('https://static.diy-kitchens.com/material_swatches/oak-270px.jpg');
    }

    &innova-custom-any-colour {
        background-color: #FFF;
        background-image: url('https://static.diy-kitchens.com/material_swatches/bespoke_270px.jpg');
        background-repeat: no-repeat;
    }

    &innova-petrol-blue {
        background-color: #849A9F;
        color: #FFF;
    }

    &innova-sage {
        background-color: #C0BBA2;
    }

    &innova-vanilla {
        background-color: #EFE2C9;
    }

    &innova-wenge {
        background-image: url('https://static.diy-kitchens.com/material_swatches/cento-wenge_270px.jpg');
        color: #FFF;
    }

    &innova-cento-wenge {
        background-image: url('https://static.diy-kitchens.com/material_swatches/cento-wenge_270px.jpg');
        color: #FFF;
    }

    &innova-cento-graphite {
        background-image: url('https://static.diy-kitchens.com/material_swatches/cento-graphite_270px.jpg');
        color: #FFF;
    }

    &innova-cento-oak {
        background-image: url('https://static.diy-kitchens.com/material_swatches/cento-oak_270px.jpg');
        color: #FFF;
    }

    &innova-graphite-fleetwood {
        background: url('https://static.diy-kitchens.com/assets/images/kitchens/swatches/graphite-fleetwood.jpg');
        color: #FFF;
    }

    &innova-cento-th-natural-halifax-oak,
    &innova-natural-halifax-oak {
        background: url('https://static.diy-kitchens.com/assets/images/kitchens/swatches/natural-halifax-oak.jpg');
        color: #FFF;
    }

    &innova-white-halifax-oak {
        background: url('https://static.diy-kitchens.com/assets/images/kitchens/swatches/white-halifax-oak.jpg');
        color: #FFF;
    }

    &innova-natural-davos-oak {
        background: url('https://static.diy-kitchens.com/assets/images/kitchens/swatches/natural-davos-oak.jpg');
        color: #FFF;
    }

    &innova-sepia-gladstone-oak {
        background: url('https://static.diy-kitchens.com/assets/images/kitchens/swatches/sepia-gladstone-oak.jpg');
        color: #FFF;
    }

    &innova-black-ash {
        background: url('https://static.diy-kitchens.com/assets/images/kitchens/swatches/black-ash.jpg');
        color: #FFF;
    }

    &innova-cento-th-heron-grey,
    &innova-heron-grey {
        background: rgb(165, 165, 165);
        color: #FFF;
    }

    &innova-cobham-blue {
        background: #3E4E56;
        color: #FFF;
    }

    &innova-tern-white {
        background: rgb(249, 250, 245);
    }

    &innova-grey {
        background: #AFAEA6;
        color: #FFF;
    }

    &innova-white {
        background-color: #FFF;
    }

    &innova-bronze {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/bronze.jpg);
        color: #fff;
    }

    &innova-cento-concrete,
    &innova-concrete {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/concrete.jpg);
    }

    &innova-copper {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/gloss-copper.jpg);
        color: #fff;
    }

    &innova-cento-th-dark-oak,
    &innova-dark-oak {
        background: url(https://static.diy-kitchens.com/assets/images/kitchenswatches/dark-oak.jpg);
        color: #fff;
    }

    &innova-cento-th-fir-green,
    &innova-fir-green {
        background-color: #4c5c4e;
    }

    &innova-gold-basalt {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/gloss-gold-basalt.jpg);
        color: #fff;
    }

    &innova-gold-light {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/gloss-gold-light.jpg);
        color: #fff;
    }

    &innova-cento-th-grey-vicenza-oak,
    &innova-grey-vicenza-oak {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/grey-vicenza-oak.jpg);
        color: #fff;
    }

    &innova-marine-blue {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/marine-blue.jpg);
        color: #fff;
    }

    &innova-natural {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/natural.jpg);
        color: #fff;
    }

    &innova-cento-th-natural-davos-oak,
    &innova-natural-davos-oak {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/natural-davos-oak.jpg);
        color: #fff;
    }

    &innova-reed-green {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/reed-green.jpg);
        color: #fff;
    }

    &innova-sepia {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/sepia.jpg);
        color: #fff;
    }

    &innova-silver-slate {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/gloss-silver-slate.jpg);
        color: #fff;
    }

    &innova-slate {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/slate.jpg);
        color: #fff;
    }

    &innova-washed {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/washed.jpg);
        color: #fff;
    }

    &innova-verona-wenge {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/verona-wenge.jpg);
        color: #fff;
    }

    &innova-white {
        background-color: #fff;
    }

    &innova-cento-th-white-halifax-oak,
    &innova-white-halifax-oak {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/white-halifax-oak.jpg);
        color: #fff
    }

    &innova-cento-th-white-marble,
    &innova-cento-white-marble,
    &innova_cento-white-marble {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/white-marble.jpg)
    }

    &carcase-alabaster {
        background-color: #ECE7D7;
        color: #000;
    }

    &carcase-aluminium {
        background-color: #B5B5B5;
        color: #000;
    }

    &carcase-black-brown-ferraro-oak {
        background-color: #4B3535;
        background-image: url('https://static.diy-kitchens.com/carcases/thumbs/unit_black_brown_ferraro_oak.jpg');
        color: #FFF;
    }

    &carcase-cashmere {
        background-color: #BFB5AD;
        color: #000;
    }

    &carcase-dakar {
        background-color: #AAA292;
        color: #FFF;
    }

    &carcase-ferraro-oak {
        background-color: #EDBA8E;
        background-image: url('https://static.diy-kitchens.com/carcases/thumbs/unit_ferraro_oak.jpg');
        color: #000;
    }

    &carcase-heron-grey {
        background: rgb(165, 165, 165);
        color: #FFF;
    }
    &carcase-graphite {
        background-color: #4C4D4C;
        color: #FFF;
    }

    &carcase-light-grey {
        background-color: #BBB9B4;
        color: #FFF;
    }

    &carcase-light-oak {
        background-color: #C5C6C2;
        background-image: url('https://static.diy-kitchens.com/carcases/thumbs/unit_light_oak.jpg');
        color: #000;
    }

    &carcase-light-winchester-oak {
        background-color: #C59473;
        background-image: url('https://static.diy-kitchens.com/carcases/thumbs/unit_light_winchester_oak.jpg');
        color: #000;
    }

    &carcase-mussel {
        background-color: #D1CABC;
        color: #000;
    }

    &carcase-dark-oak {
        background: url(https://static.diy-kitchens.com/assets/images/kitchenswatches/dark-oak.jpg);
        color: #FFF;
    }

    &carcase-cento-concrete {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/concrete.jpg);
        color: #000;
    }

    &carcase-cento-slate {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/slate.jpg);
        color: #000;
    }

    &carcase-cento-white-marble {
        background: url(https://static.diy-kitchens.com/assets/images/kitchens/swatches/white-marble.jpg);
        color: #000;
    }

    &carcase-heritage-green {
        background-color: #263028;
        color: #FFF;
    }
    
    &carcase-knightsbridge-blue {
        background-color: #213343;
        color: #FFF;
    }

    &carcase-fir-green {
        background-image: url('https://static.diy-kitchens.com/assets/images/kitchenswatches/fir-green.jpg');
        color: #FFF;
    }

    &carcase-natural-lancaster-oak {
        background-color: #B49175;
        background-image: url('https://static.diy-kitchens.com/carcases/thumbs/unit_natural_lancaster_oak.jpg');
        color: #000;
    }

    &carcase-sand-beige {
        background-color: #D6C7B8;
        color: #000;
    }

    &carcase-stone-grey {
        background-color: #9C8E82;
        color: #FFF;
    }

    &carcase-tobacco-aida-walnut {
        background-color: #6F4E3B;
        background-image: url('https://static.diy-kitchens.com/carcases/thumbs/unit_tobacco_aida_walnut.jpg');
        color: #FFF;
    }

    &carcase-vanilla {
        background-color: #EFE2C9;
        color: #000;
    }

    &carcase-wenge {
        background-image: url('https://static.diy-kitchens.com/material_swatches/cento-wenge_270px.jpg');
        color: #FFF;
    }

    &carcase-white {
        background-color: #FFF;
        border: 1px solid color('copy-border');
        color: #000;
    }
}

$swatches: (
    innova: (
        alabaster:          #F0E5D2,
        black:              #121519,
        carbon:             #4C4D4C,
        cashmere:           #BFB5AD,
        champagne:          #837A71,
        cornflower-blue:    #b6c0bd,
        cream:              #F0E5D2,
        dakar:              #AAA292,
        dove-grey:          #C3C1BC,
        graphite:           #4C4D4C,
        lamp-room-grey:     #AFAEA6,
        light-grey:         #C3C1BC,
        matt-white:         #FFF,
        mussel:             #D1CABC,
        dark-oak:           #D1CABC,
        cento-concrete:     #D1CABC,
        cento-slate:        #D1CABC,
        cento-white-marble: #D1CABC,
        heritage-green:     #263028,
        knightsbridge-blue: #213343,
        fir-green :         #27362A,
        northern-tide:      #749398,
        petrol-blue:        #849A9F,
        sage:               #C0BBA2,
        vanilla:            #EFE2C9,
        oak:                  image('material_swatches', 'oak-270px.jpg'),
        wenge:                image('material_swatches', 'cento-wenge_270px.jpg')
    ),
    helmsley: ()
);
