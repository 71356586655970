.contained {
    margin: 0 auto;
    max-width: 1300px;

    @media only screen and (max-width: 1360px) {
        margin: 0 30px;
    }

    @media only screen and (max-width: $mobile-width) {
        margin: 0 20px;
    }
}

.container {
    width: 100%;
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.pv-1 {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1300px !important;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

@media (max-width: 768px) {
    .container.container-column-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .col-12 {
        flex: 1;
    }

    .order-1 {
        order: 1;
    }

    .order-2 {
        order: 2;
    }

    .pv-0 {
        padding-bottom: 0rem;
        padding-top: 0rem;
    }
}

@media (min-width: 1200px) {
    .col-1-third {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-3-thirds {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .offset-third {
        margin-left: 16.666667%;
    }
}

@media only screen and (max-width: 1200px) {
    .flex {
        flex-wrap: wrap;
    }

    .flex-space-between {
        &.wrap-under-1200 {
            flex-wrap: wrap;

            .flex-item {
                margin: 0 auto 20px;
                width: 100%;
            }
        }
    }

    .flex-space-evenly {
        &.wrap-under-1200 {
            flex-wrap: wrap;

            .flex-item {
                margin: 0 auto 20px;
                width: 100%;
            }
        }
    }
}

.flex {
    display: flex;

    &.wrap {
        flex-wrap: wrap;
    }

    &.flex-compact {
        .flex-item {
            &.a-half {
                width: calc(100% / 2);
            }

            &.a-third {
                width: calc(100% / 3);
            }

            &.a-fourth {
                width: calc(100% / 4);
            }

            &.a-fifth {
                width: calc(100% / 5);
            }
        }
    }

    .flex-item {
        margin: 0 20px;
    }
}

.flex-space-between {
    display: flex;
    justify-content: space-between;

    &.wrap {
        flex-wrap: wrap;

        .flex-item {
            margin-bottom: 20px;
        }
    }
}

.flex-center {
    display: flex;
    justify-content: center;

    &.wrap {
        flex-wrap: wrap;

        .flex-item {
            margin-bottom: 20px;
        }
    }
}

.flex-space-evenly {
    display: flex;
    justify-content: space-evenly;

    &.wrap {
        flex-wrap: wrap;

        .flex-item {
            margin-bottom: 20px;
        }
    }
}

.flex-item {
    margin: 0 15px;

    &.a-half {
        flex: 0 0 auto;
        margin: 0;
        width: calc((100% - 20px)/2);
    }

    &.a-third {
        flex: 0 0 auto;
        margin: 0;
        width: calc((100% - 60px)/3);
    }

    &.a-fourth {
        flex: 0 0 auto;
        margin: 0;
        width: calc((100% - 60px)/4);
    }

    &.a-fifth {
        flex: 0 0 auto;
        margin: 0;
        width: calc((100% - 60px)/5);
    }
}

@media only screen and (max-width: 1024px) {
    .flex {
        flex-wrap: wrap;
    }

    .flex-space-between {
        &.wrap-under-1024 {
            flex-wrap: wrap;

            .flex-item {
                margin: 0 auto 20px;
                width: 100%;
            }
        }
    }

    .flex-space-evenly {
        &.wrap-under-1024 {
            flex-wrap: wrap;

            .flex-item {
                margin: 0 auto 20px;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .flex {
        flex-wrap: wrap;
    }

    .flex-space-between {
        &.wrap-under-800 {
            flex-wrap: wrap;

            .flex-item {
                margin: 0 auto 20px;
                width: 100%;
            }
        }
    }

    .flex-space-evenly {
        &.wrap-under-800 {
            flex-wrap: wrap;

            .flex-item {
                margin: 0 auto 20px;
                width: 100%;
            }
        }
    }
}