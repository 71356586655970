.promotion-directive- {
    &offer-overlay {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 20%;
    }

    &product-listing-offer-overlay {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30%;
    }

    &unit-detail-offer-overlay {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 24%;
    }

    &door-detail-offer-overlay {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 35%;
    }

    &sale-price {
        color: red;
        font-weight: 600;

        &::before {
            content: 'NOW ';
            font-size: 0.9rem;
        }
    }

    &strike-through {
        margin: 0;
        font-size: 1rem;

        .currency {
            text-decoration: line-through;
        }
    }

    &offer-button {
        &+.promotion-directive-offer-button {
            margin-left: 15px;
        }

        &:hover {
            p {
                text-decoration: underline;
            }
        }

        p {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 5px 10px;
            border-radius: 5px;
            color: white;
            font-weight: 400;
        }
    }
}
