@use '@angular/material' as mat;

@import '/src/theme/variables';

$typography: mat.m2-define-typography-config(
    $font-family: font('opensans')
);

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    font-family: font('opensans');

    &.playfair {
        font-family: font('playfair');
    }
}

p {
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.777rem;
    letter-spacing: 0.031rem;

    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
        margin-top: 5.61rem;
    }

    &.semibold {
        font-weight: 600;
    }

    &.bold {
        font-weight: 700;
    }

    &.nomargin {
        margin-bottom: 0;
    }

    &.big {
        margin-bottom: 1.333rem;
        font-size: 1.333rem;
        line-height: 1.777rem;
    }

    &.small {
        margin-bottom: 0.75rem;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.333rem;

        &.bold {
            font-weight: 700;
        }
    }

    &.subtext {
        font-style: italic;
        font-size: 0.8rem;
        line-height: 2rem;
    }
}

h1 {
    margin: 2rem 0;
    font-size: 4rem;
    font-weight: 300;
    line-height: 3rem;

    &.feature-display {
        margin-bottom: 4.209rem;
        font-size: 9.969rem;
        font-style: italic;
        line-height: 9.969rem;
    }

    &.small {
        margin-bottom: 1rem;
        font-family: font('opensans');
        font-size: 1.75rem;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
        text-transform: uppercase;
    }
}

h2 {
    margin-bottom: 2rem;
    font-size: 3.157rem;
    font-weight: 300;
    line-height: 4.209rem;

    &.small {
        margin-bottom: 1.333rem;
        font-family: font('opensans');
        font-size: 1.333rem;
        font-weight: 700;
        line-height: 1.777rem;
        letter-spacing: 0.24rem;
    }
}

h3 {
    margin: 2rem 0;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 3.157rem;
}

h4 {
    margin: 1rem 0;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.369rem;
    letter-spacing: 0.125rem;

    &.wide {
        letter-spacing: 0.422rem;
    }
}

h5 {
    margin: 1.3rem 0;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.777rem;

    &.wide {
        letter-spacing: 0.317rem;
    }
}

h6 {
    margin: 1.333rem 0;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.333rem;
    letter-spacing: 0.125rem;

    &.wide {
        letter-spacing: 0.238rem;
    }
}

span {
    &.semibold {
        font-weight: 600;
    }

    &.bold {
        font-weight: 700;
    }

    &.wide {
        letter-spacing: 0.17rem;
    }
}

.capitalise {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    h1 {
        margin-bottom: 1.777rem;
        font-size: 3.157rem;
        font-weight: 400;
        line-height: 3.157rem;

        &.small {
            margin-bottom: 1.333rem;
            font-size: 1.777rem;
            line-height: 1.777rem;
            letter-spacing: 0.317rem;
        }

        &.wide {
            letter-spacing: 0.317rem;
        }
    }

    h2 {
        margin-bottom: 1.777rem;
        font-size: 2.369rem;
        font-weight: 400;
        line-height: 3.157rem;
    }

    h3 {
        margin-bottom: 1.777rem;
        font-size: 1.777rem;
        font-weight: 400;
        line-height: 2.369rem;
    }

    h4 {
        margin-bottom: 1rem;
        font-size: 1.333rem;
        line-height: 1.777rem;

        &.wide {
            letter-spacing: 0.317rem;
            text-transform: uppercase;
        }
    }

    h5 {
        margin-bottom: 0.1rem;
        font-size: 1.5rem;
        line-height: 1.333rem;

        &.wide {
            letter-spacing: 0.238rem;
            text-transform: uppercase;
        }
    }

    h6 {
        margin-bottom: 1rem;
        font-size: 1rem;
        line-height: 1.333rem;

        &.wide {
            letter-spacing: 0.238rem;
            text-transform: uppercase;
        }
    }

    p {
        margin-bottom: 1rem;
        font-size: 1rem;
        line-height: 1.777rem;

        &.small {
            margin-bottom: 0.75rem;
            font-size: 0.75rem;
            line-height: 1.333rem;
        }
    }
}

@media only screen and (max-width: $mobile-width) {
    h1 {
        margin-bottom: 1.777rem;
        font-size: 3.157rem;
        font-weight: 400;
        line-height: 3.157rem;

        &.small {
            margin-bottom: 1.333rem;
            font-size: 1.777rem;
            line-height: 1.777rem;
            letter-spacing: 0.317rem;
        }

        &.wide {
            letter-spacing: 0.317rem;
        }
    }

    h2 {
        padding: 1rem 0;
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.5rem;
    }

    h3 {
        margin-bottom: 1.777rem;
        font-size: 1.777rem;
        font-weight: 400;
        line-height: 2.369rem;
    }

    h4 {
        margin-bottom: 1rem;
        font-size: 1.333rem;
        line-height: 1.777rem;

        &.wide {
            letter-spacing: 0.317rem;
            text-transform: uppercase;
        }
    }

    h5 {
        margin-bottom: 0.1rem;
        font-size: 1.5rem;
        line-height: 1.333rem;

        &.wide {
            letter-spacing: 0.238rem;
            text-transform: uppercase;
        }
    }

    h6 {
        margin-bottom: 1rem;
        font-size: 1rem;
        line-height: 1.333rem;

        &.wide {
            letter-spacing: 0.238rem;
            text-transform: uppercase;
        }
    }

    p {
        margin-bottom: 1rem;
        font-size: 1rem;
        line-height: 1.777rem;

        &.small {
            margin-bottom: 0.75rem;
            font-size: 0.75rem;
            line-height: 1.333rem;
        }
    }
}
