@import '/src/theme/variables';

.content-header-background {
    position: relative;
    z-index: 0;

    &.content-header-corner {
        margin-top: -52px;
        padding-top: 52px;
        z-index: 0;
        
        &::before {
            background-position-y: top;
            background-position-x: right;
            background-size: cover;
            height: 100%;
        }

        @media only screen and (max-width: 992px) {
            background-image: none;

            &::before {
                display: none;
            }

            p {
                padding-right: 0;
            }
        }
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: calc(100% - 60px);
        background-size: contain;
        width: 40%;
        height: calc(100% - 30px);
    }

    p {
        padding-right: 40%;
    }

    // Kitchens
    &.content-header-kitchens::before {
        background-image: url('https://static.diy-kitchens.com/categories/kitchens/category-images_page-header/category-image_page-header_doors_all.png');
    }

    &.content-header-kitchens-all-styles::before {
        background-image: url('https://static.diy-kitchens.com/categories/kitchens/category-images_page-header/category-image_page-header_doors_all.png');
    }

    &.content-header-kitchens-shaker::before {
        background-image: url('https://static.diy-kitchens.com/categories/kitchens/category-images_page-header/category-image_page-header_doors_shaker.png');
    }

    &.content-header-kitchens-handleless::before {
        background-image: url('https://static.diy-kitchens.com/categories/kitchens/category-images_page-header/category-image_page-header_doors_handleless.png');
    }

    &.content-header-kitchens-high-gloss::before {
        background-image: url('https://static.diy-kitchens.com/categories/kitchens/category-images_page-header/category-image_page-header_doors_high_gloss.png');
    }

    &.content-header-kitchens-in-frame::before::before {
        background-image: url('https://static.diy-kitchens.com/categories/kitchens/category-images_page-header/category-image_page-header_doors_inframe.png');
    }

    &.content-header-kitchens-in-frame-effect::before {
        background-image: url('https://static.diy-kitchens.com/categories/kitchens/category-images_page-header/category-image_page-header_doors_inframe_effect.png');
    }

    &.content-header-kitchens-modern::before {
        background-image: url('https://static.diy-kitchens.com/categories/kitchens/category-images_page-header/category-image_page-header_doors_modern.png');
    }

    &.content-header-kitchens-traditional::before {
        background-image: url('https://static.diy-kitchens.com/categories/kitchens/category-images_page-header/category-image_page-header_doors_traditional.png');
    }

    &.content-header-kitchens-bespoke-painted::before {
        background-image: url('https://static.diy-kitchens.com/categories/kitchens/category-images_page-header/category-image_page-header_doors_bespoke_painted.png');
    }

    // Units
    &.content-header-units::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_base_unit.jpg');
    }

    &.content-header-units-base::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_base_unit.jpg');
    }

    &.content-header-units-corner-base::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_corner_base_unit.jpg');
    }

    &.content-header-units-wall::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_wall_unit.jpg');
    }

    &.content-header-units-corner-wall::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_corner_wall_unit.jpg');
    }

    &.content-header-units-appliance-housings::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_appliance_housings.jpg');
    }

    &.content-header-units-tall::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_tall_unit.jpg');
    }

    &.content-header-units-tall-corner::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_tall_unit.jpg');
    }

    &.content-header-units-dresser::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_mantle_unit.jpg');
    }

    &.content-header-units-accessories::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_plinth_panels.jpg');
    }

    &.content-header-units-mid-height::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_mid_unit.jpg');
    }

    // Units - Base
    &.content-header-units-base-all::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_base_unit.jpg');
    }

    &.content-header-units-base-highline::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_highline.jpg');
    }
    
    &.content-header-units-base-drawerline::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_drawerline.jpg');
    }
    
    &.content-header-units-base-pan-and-multi-drawer::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_pan_&_multi_drawer_unit.jpg');
    }
    
    &.content-header-units-base-sink::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_sink_unit.jpg');
    }
    
    &.content-header-units-base-pull-out-storage::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_pull_out_unit.jpg');
    }
    
    &.content-header-units-base-pull-out-waste-units::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_bin_unit.jpg');
    }
    
    &.content-header-units-base-curved-and-s-shaped::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_curved_unit.jpg');
    }
    
    &.content-header-units-base-slimline::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_reduced_depth_unit.jpg');
    }
    
    &.content-header-units-base-open::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_open_unit.jpg');
    }
    
    &.content-header-units-base-wine-rack::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_wine_rack.jpg');
    }
    
    &.content-header-units-base-end-quadrant::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_open_end_&_quadrant.jpg');
    }
    
    &.content-header-units-base-peninsular::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_peninsular_unit.jpg');
    }
    
    &.content-header-units-base-reduced-height::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_reduced_height_unit.jpg');
    }
    
    &.content-header-units-base-900mm-high::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_tall_base.jpg');
    }
    
    &.content-header-units-base-1250mm-high::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/mid_unit.jpg');
    }
    
    &.content-header-units-base-reduced-height::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_reduced_height_unit.jpg');
    }
    
    &.content-header-units-base-angled::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_angled_unit.jpg');
    }

    // Units - Corner base
    &.content-header-units-corner-base-all::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_corner_base_unit.jpg');
    }

    &.content-header-units-corner-base-standard::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/corner-base_standard_corner.jpg');
    }
    
    &.content-header-units-corner-base-sink::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/corner-base_sink_corner.jpg');
    }
    
    &.content-header-units-corner-base-pull-out::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/corner-base_pull_out_corner.jpg');
    }
    
    &.content-header-units-corner-base-l-shaped::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/corner-base_l-shape.jpg');
    }
    
    &.content-header-units-corner-base-diagonal::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/corner-base_diagonal_corner_unit.jpg');
    }
    
    &.content-header-units-corner-base-internal-curved::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/corner-base_internal_curve.jpg');
    }
    
    // Units - Wall
    &.content-header-units-wall-all::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_wall_unit.jpg');
    }

    &.content-header-units-wall-standard::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/wall_standard_unit.jpg');
    }
    
    &.content-header-units-wall-curved-and-s-shaped::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/wall_curved_unit.jpg');
    }
    
    &.content-header-units-wall-open::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/wall_open_unit.jpg');
    }
    
    &.content-header-units-wall-angled::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/wall_angled_unit.jpg');
    }
    
    &.content-header-units-wall-peninsular::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/wall_peninsular_unit.jpg');
    }
    
    &.content-header-units-wall-end-quadrant::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/wall_open_end_&_quadrant.jpg');
    }
    
    &.content-header-units-wall-wine-rack::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/wall_wine_rack.jpg');
    }
    
    &.content-header-units-wall-bi-fold::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/wall_bi-fold_unit.jpg');
    }
    
    &.content-header-units-wall-top-boxes::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/wall_top_boxes.jpg');
    }
    
    &.content-header-units-wall-boiler-housing::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/wall_boiler_unit.jpg');
    }
    
    &.content-header-units-wall-canopy::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/category_units_wall-canopy.jpg');
    }
    
    // Units - Corner Wall
    &.content-header-units-corner-wall-all::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_corner_wall_unit.jpg');
    }

    &.content-header-units-corner-wall-standard-corner::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/corner_wall-standard.jpg');
    }
    
    &.content-header-units-corner-wall-l-shape::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/corner_wall-lshape.jpg');
    }
    
    &.content-header-units-corner-wall-internal-curve::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/corner_wall-internal-curved.jpg');
    }
    
    &.content-header-units-corner-wall-angled::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/corner_wall-angled.jpg');
    }
    
    // Units - Appliance Housings
    &.content-header-units-appliance-housings-all::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_appliance_housings.jpg');
    }

    &.content-header-units-appliance-housings-microwave::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/base_appliance_housing.jpg');
    }
    
    &.content-header-units-appliance-housings-single-oven::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/single_oven_tall.jpg');
    }
    
    &.content-header-units-appliance-housings-single-oven-\&-microwave::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/single_oven_tall.jpg');
    }
    
    &.content-header-units-appliance-housings-double-oven::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/double_oven_tall.jpg');
    }
    
    &.content-header-units-appliance-housings-double-oven-\&-microwave::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/double_oven_tall.jpg');
    }
    
    &.content-header-units-appliance-housings-refrigeration::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/integrated_50_50_fridge_freezer.jpg');
    }
    
    &.content-header-units-appliance-housings-compact-appliance::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/microwaves_compact_appliances.jpg');
    }
    
    &.content-header-units-appliance-housings-x2-compact-appliance::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/microwaves_compact_appliances.jpg');
    }

    // Units - Tall
    &.content-header-units-tall-all::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_tall_unit.jpg');
    }

    &.content-header-units-tall-broom::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall_broom_unit.jpg');
    }
    
    &.content-header-units-tall-larder::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall_larder_unit.jpg');
    }
    
    &.content-header-units-tall-pull-out-larder::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall_pull_out_unit.jpg');
    }
    
    &.content-header-units-tall-internal-drawer::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall_internal_drawer_unit.jpg');
    }
    
    &.content-header-units-tall-angled::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall_angled_unit.jpg');
    }
    
    &.content-header-units-tall-wine-rack::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall_wine_rack.jpg');
    }
    
    &.content-header-units-tall-quadrant::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall_open_end_&_quadrant.jpg');
    }
    
    // Units - Tall Corner
    &.content-header-units-tall-corner-all::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_tall_unit.jpg');
    }

    &.content-header-units-tall-corner-walk-in-larder::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall-corner_walk_in_larder.jpg');
    }
    
    &.content-header-units-tall-corner-diagonal::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall-corner_diagonal_corner_unit.jpg');
    }
    
    &.content-header-units-tall-corner-tall-corner::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall-corner_tall_corner.jpg');
    }
    
    &.content-header-units-tall-corner-reduced-depth-tall-corner::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/tall-corner_reduced_depth_tall_corner.jpg');
    }
    
    // Units - Worktop Dresser
    &.content-header-units-dresser-all::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_mantle_unit.jpg');
    }

    &.content-header-units-dresser-open::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_open_dresser_unit.jpg');
    }
    
    &.content-header-units-dresser-tambour::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_tambour_unit.jpg');
    }
    
    &.content-header-units-dresser-standard::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_dresser_unit.jpg');
    }
    
    &.content-header-units-dresser-mantles::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_mantle.jpg');
    }
    
    &.content-header-units-dresser-mantles-canopies::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_mantle.jpg');
    }

    // Units - Accessories
    &.content-header-units-accessories-all::before {
        background-image: url('https://static.diy-kitchens.com/categories/units/category-images_page-header/category-image_page-header_plinth_panels.jpg');
    }

    &.content-header-units-accessories-appliance-doors::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/appliance_doors.jpg');
    }
    
    &.content-header-units-accessories-cornice-pelmet-plinths::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/pelmet.jpg');
    }
    
    &.content-header-units-accessories-end-panels::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/plinth_panels.jpg');
    }
    
    &.content-header-units-accessories-corner-posts::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/cornerpost.jpg');
    }
    
    &.content-header-units-accessories-mantles-canopies::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_mantle.jpg');
    }
    
    &.content-header-units-accessories-decorative-items::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/units/posts,-panels-&-shelves_column.jpg');
    }

    // Appliances - Cooking
    &.content-header-appliances-cooking::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_single-ovens.jpg');
    }

    &.content-header-appliances-cooking-single-ovens::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_single-ovens.jpg');
    }

    &.content-header-appliances-cooking-double-ovens::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_single-ovens.jpg');
    }

    &.content-header-appliances-cooking-compact-ovens::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_single-ovens.jpg');
    }

    &.content-header-appliances-cooking-microwaves::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_microwave.jpg');
    }

    &.content-header-appliances-cooking-hobs::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_hobs.jpg');
    }

    &.content-header-appliances-cooking-extractors::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_extractors.jpg');
    }

    &.content-header-appliances-cooking-warming-drawers::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_warming_drawer.jpg');
    }

    // Appliances - Refrigeration
    &.content-header-appliances-refrigeration::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_refrigeration.jpg');
    }

    &.content-header-appliances-refrigeration-fridges::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_refrigeration.jpg');
    }

    &.content-header-appliances-refrigeration-freezers::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_refrigeration.jpg');
    }

    &.content-header-appliances-refrigeration-50-50-fridge-freezers::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_refrigeration.jpg');
    }

    &.content-header-appliances-refrigeration-70-30-fridge-freezers::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_refrigeration.jpg');
    }

    &.content-header-appliances-refrigeration-fridge-freezers::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_refrigeration.jpg');
    }

    &.content-header-appliances-refrigeration-wine-coolers::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_refrigeration.jpg');
    }

    // Laundry
    &.content-header-appliances-laundry::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/category-header_laundry.jpg');
    }

    &.content-header-appliances-laundry-washing-machines::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/category-header_laundry.jpg');
    }

    &.content-header-appliances-laundry-tumble-dryer::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/category-header_laundry.jpg');
    }

    &.content-header-appliances-laundry-washer-dryers::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/category-header_laundry.jpg');
    }

    // Dishwashers
    &.content-header-appliances-dishwashers::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_dishwasher.jpg');
    }

    &.content-header-appliances-dishwashers-slimline-integrated::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_dishwasher.jpg');
    }

    &.content-header-appliances-dishwashers-integrated::before {
        background-image: url('https://static.diy-kitchens.com/categories/appliances/category-images_page-header/header_category_dishwasher.jpg');
    }

    // Sinks
    &.content-header-sinks::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_sinks_belfast.jpg');
    }

    &.content-header-sinks-inset::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_sinks_inset.jpg');
    }

    &.content-header-sinks-undermount::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_sinks_undermount.jpg');
    }

    &.content-header-sinks-belfast::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_sinks_belfast.jpg');
    }

    // Taps
    &.content-header-taps::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_taps_mixer.jpg');
    }

    &.content-header-taps-accessory::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_taps_mixer.jpg');
    }

    &.content-header-taps-bridge::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_taps_mixer.jpg');
    }

    &.content-header-taps-mixer::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_taps_mixer.jpg');
    }

    &.content-header-taps-pull-out::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_taps_pull_out.jpg');
    }

    &.content-header-taps-quooker-boiling-water::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_taps_mixer.jpg');
    }

    &.content-header-taps-tap::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_taps_mixer.jpg');
    }

    &.content-header-taps-spout::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-image_page-header_taps_spout.jpg');
    }

    // Handles
    &.content-header-handles-bar-handles::before {
        background-image: url('https://static.diy-kitchens.com/categories/handles/category-images_page-header/category-image_page-header_handles_bar-handles.jpg');
    }

    &.content-header-handles-bow-and-bridge-handles::before {
        background-image: url('https://static.diy-kitchens.com/categories/handles/category-images_page-header/category-image_page-header_handles_bow-and-bridge-handles.jpg');
    }

    &.content-header-handles-knobs::before {
        background-image: url('https://static.diy-kitchens.com/categories/handles/category-images_page-header/category-image_page-header_handles_knobs.jpg');
    }

    &.content-header-handles-profile-handles::before {
        background-image: url('https://static.diy-kitchens.com/categories/handles/category-images_page-header/category-image_page-header_handles_profile-handles.jpg');
    }

    &.content-header-handles-cup-and-pull-handles::before {
        background-image: url('https://static.diy-kitchens.com/categories/handles/category-images_page-header/category-image_page-header_handles_cup-and-pull-handles.jpg');
    }

    // Accessories
    &.content-header-accessories-components::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/category_accessories_components.jpg');
    }

    &.content-header-accessories-cutlery-inserts::before {
        background-image: url('https://static.diy-kitchens.com/categories/accessories/category_cutlery-inserts_1000px.jpg');
    }

    &.content-header-accessories-drawer-boxes::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/category_accessories_drawer-boxes.jpg');
    }

    &.content-header-accessories-glass-shelves::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/category_accessories_glass-shelves.jpg');
    }

    &.content-header-accessories-lighting::before {
        background-image: url('https://static.diy-kitchens.com/categories/accessories/category_lighting_1000px.jpg');
    }

    &.content-header-accessories-waste-systems::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/category_accessories_waste-systems.jpg');
    }

    &.content-header-accessories-worktops::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/category_worktops_solid-wood.jpg');
    }

    &.content-header-accessories-sinks::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category_sinks_1000px.jpg');
    }

    &.content-header-accessories-unit-accessories::before {
        background-image: url('https://static.diy-kitchens.com/assets/images/menu/category_accessories_panels.jpg');
    }

    // Worktops
    &.content-header-worktops::before {
        background-image: url('https://static.diy-kitchens.com/categories/worktops/category-images_page-header/category-image_page-header_worktops_all.png');
    }

    &.content-header-solid-wood::before {
        background-image: url('https://static.diy-kitchens.com/categories/worktops/category-images_page-header/category-image_page-header_worktops_wood.png');
    }

    &.content-header-laminate::before {
        background-image: url('https://static.diy-kitchens.com/categories/worktops/category-images_page-header/category-image_page-header_worktops_laminate.png');
    }

    &.content-header-quartz::before {
        background-image: url('https://static.diy-kitchens.com/categories/worktops/category-images_page-header/category-image_page-header_worktops_quartz.png');
    }

    &.content-header-dekton::before {
        background-image: url('https://static.diy-kitchens.com/categories/worktops/category-images_page-header/category-image_page-header_worktops_dekton.png');
    }

    &.content-header-ceramic::before {
        background-image: url('https://static.diy-kitchens.com/categories/worktops/category-images_page-header/category-image_page-header_worktops_ceramic.png');
    }

    &.content-header-granite::before {
        background-image: url('https://static.diy-kitchens.com/categories/worktops/category-images_page-header/category-image_page-header_worktops_granite.png');
    }

    &.content-header-sinks-and-taps::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-header-image_sinks-and-taps.jpg');
    }

    &.content-header-sinks::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-header-image_sinks.jpg');
    }

    &.content-header-taps::before {
        background-image: url('https://static.diy-kitchens.com/categories/sinks-and-taps/category-images_page-header/category-header-image_taps.jpg');
    }

    &.content-contained {

        &::before {
            margin-top: -62px;
            height: calc(100% + 62px);
            background-size: auto 150%;
        }

        @media (max-width: 992px) {
            &::before {
                display: none;
            }

            p {
                padding-right: 0;
            }
        }
    }
}

.header-banner,
.mobile-header {
    background-color: color('home-page-banner');
}

.logo {
    display: inline-block;
    margin-right: auto;
    background-image: url('https://static.diy-kitchens.com/assets/images/home/Logo_DIY-Kitchens_White.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 232px;
    height: 50px;
}

.menu {
    background-color: #48ACF0;
}

.here-to-help {
    flex: 0 1 auto;
    margin: 0 20px;
    padding: 0 30px 0 0;
    background-image: url('https://static.diy-kitchens.com/assets/images/general/olivia-icon.png');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 40px;
}

@media only screen and (max-width: 1200px) {
    .header {
        .header-right {
            .logo {
                padding: 9px 0;
                width: 200px;
                height: 45px;
            }

            .here-to-help {
                margin: 0 10px;
            }
        }
    }

    .logo {
        width: 200px;
        height: 45px;
    }
}

.dark {
    background-color: #FFF;
    color: #444;

    hr {
        border-color: #999;
    }
}

.mat-expansion-panel-header {
    background-color: color('brand-secondary');
}
