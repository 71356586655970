@use '@angular/material' as mat;

@include mat.core();

@import './variables';

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Playfair+Display:400,400i&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import './fonts';

/** Reset first */
@import './reset';

/** Typography */
@import './typography';

/** Theming */
@import './theme-default';

/** Components and Helpers */
@import './helpers';
@import './components/flex';
@import './components/grid';
@import './components/swatches';
@import './components/promotion-directive';
@import './components/froala';
@import './components/angular-material';
@import './components/currency-format';
@import './components/general';
@import './components/headers';
@import './components/images';
