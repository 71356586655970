@import '/src/theme/variables';

html,
body {
    overscroll-behavior-y: none;
    margin: 0;
    color: color('black');
    font-family: font('opensans');
    font-size: 16px;
    font-weight: 300;
    line-height: 1rem;
    letter-spacing: 0.05rem;
}

a {
    color: color('black');
    text-decoration: none;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1300px;
    }
}

